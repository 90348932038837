<template>

  <div
      style="background-color:#272727;"

  >
    <hello-world />
  </div>
</template>

<script>
  import HelloWorld from '../components/HelloWorld'

  export default {
    name: 'Home',

    components: {
      HelloWorld,
    },

    created() {

      //window.location.href = "/i"


    }

  }




</script>
